import { DIFFICULTY_LEVELS, PRIVILEGIOS, RECIPE_STATUS, tienePermisoDe } from '../../providers/utils/functions'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as Ico2 } from '../../images/svg/ico-2.svg'
import { ReactComponent as Ico3 } from '../../images/svg/ico-3.svg'
import { ReactComponent as Ico4 } from '../../images/svg/ico-4.svg'
import { ReactComponent as IcoDif } from '../../images/svg/ico-1-dif.svg'
import { ReactComponent as IcoFac } from '../../images/svg/ico-1-fac.svg'
import { ReactComponent as IcoImprimir } from '../../images/svg/ico-imprimir.svg'
import { ReactComponent as IcoMed } from '../../images/svg/ico-1-med.svg'
import { slugify, useServiceProvider } from '../../providers/service'
import { Tooltip } from 'react-tooltip'
import { useAuthProvider } from '../../providers/auth'
import { useDataProvider } from '../../providers/data'
import { useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import BeatLoader from 'react-spinners/BeatLoader'
import CookingStepsText from '../../components/CookingSteps'
import Dialog from '../../components/Dialog'
import EditRecipeButton from '../../components/EditRecipeButton'
import FavoriteButton from '../../components/FavoriteButton'
import FoldersToRecipeLightbox from '../../components/folders/FoldersToRecipeLightbox'
import StarRating from '../../components/StarRating'
import StarRatingForm from '../../components/StarRatingForm'
import './RecipePage.scss'
import { useTranslation } from "react-i18next";

const override = {
	display: 'block',
	margin: '0 auto',
	borderColor: '#509F2C',
	width: 'fit-content',
}

function formatTime(time) {
	if (!time) return '-- min'
	const hours = Math.floor(time / 3600)
	const minutes = Math.floor((time % 3600) / 60)
	const seconds = Math.floor(time % 60)

	if (hours > 0) {
		if (minutes > 0) return `${hours} h ${minutes} min`
		else return `${hours} h`
	} else if (seconds > 0) return `${minutes} min ${seconds} seg`
	return `${minutes} min`
}

function RecipePage() {
	const { t, i18n } = useTranslation();
	const { id: idReceta } = useParams()
	const componentRef = useRef()

	// Proveedor de autenticación
	const authProvider = useAuthProvider()
	const favorites = authProvider.getFavorites()
	const isFavourite = favorites.length ? favorites.includes(Number(idReceta)) : null
	const isAuthenticated = authProvider.checkAuth()
	const userData = authProvider.getIdentity()

	// Proveedor de datos
	const dataProvider = useDataProvider()

	const navigate = useNavigate()

	const searchEngineDataProvider = useDataProvider('searchEngine')

	const handleValidation = () => {
		window.open('https://docs.google.com/forms/d/1tW3TRfeQ-2XuGZl1uMmVTr10TaT-mEAJHq2PqNvj2Ts/viewform?edit_requested=true', '_blank')
	}

	const handlePrint = () => {
		window.print()
	}

	const {
		isLoading: recipePageIsLoading,
		isError: recipePageIsError,
		error: recipePageError,
		data: recipePageData,
	} = useQuery(['recipePage', { id: idReceta }], async () => {
		const { data } = await dataProvider.getOne(`api/recetas/${idReceta}`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
				...(isAuthenticated ? { Authorization: userData.jwt } : {}),
			},
		})
		return data
	})

	const translate = Math.max(recipePageData?.traducciones?.findIndex(({ codLenguaje }) => codLenguaje === i18n.resolvedLanguage) || 0, 0)
	const idRecetaOriginal = recipePageData?.traducciones?.[translate]?.idRecetaOriginal ?? null;
	const ingredientesRO = recipePageData?.traducciones?.[translate]?.ingredientes ?? null;

	const {
		isLoading: originalRecipeIsLoading,
		isError: originalRecipeIsError,
		error: originalRecipeError,
		data: originalRecipeData,
	} = useQuery(
		['originalRecipe', { id: idRecetaOriginal }],
		async () => {
			if (idRecetaOriginal) {
				const { data } = await dataProvider.getOne(`api/recetas/${idRecetaOriginal}`, {
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json, text/plain, */*',
						...(isAuthenticated ? { Authorization: userData.jwt } : {}),
					},
				})
				return data
			} else {
				return null
			}
		},
		{
			enabled: idRecetaOriginal ? true : false,
		}
	)

	const originalRecipeTranslate = Math.max(originalRecipeData?.traducciones?.findIndex(({ codLenguaje }) => codLenguaje === i18n.resolvedLanguage) || 0, 0)

	const {
		isLoading: difficultiesIsLoading,
		isError: difficultiesIsError,
		error: difficultiesError,
		data: difficultiesData,
	} = useQuery(['difficulties', {}], async () => {
		const { data: difficultyLevelsData } = await dataProvider.getList(`api/dificultad/list`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
			},
		})

		let difficultyLevels = []
		difficultyLevelsData.forEach((element) => {
			difficultyLevels[element.idDificultad] = element.nivel
		})
		return difficultyLevels
	})

	const {
		isLoading: variantRecipesIsLoading,
		isError: variantRecipesIsError,
		error: variantRecipesError,
		data: variantRecipesData,
	} = useQuery(
		["variantRecipes", { id: idReceta }],
		async () => {
			const { data } = await searchEngineDataProvider.search({
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json, text/plain, */*',
					...(isAuthenticated ? { Authorization: userData.jwt } : {}),
				},
				params: {
					page: 1,
					perPage: 50,
					filters: {
						idRecipeOrigin: idReceta,
						includeVariants: true,
					},
					sort: [{
						"porciones": "asc"
					}]
				},
			})
			return data.sort((a, b) => a.porciones - b.porciones)
		},
		{
			enabled: recipePageData ? true : false,
		}
	)

	const [showDialog, setShowDialog] = useState(false)
	const [showDialogDelete, setShowDialogDelete] = useState(false)
	const [commentsData, setCommentsData] = useState([])
	const [commentText, setCommentText] = useState('')
	const [idCommentEdit, setIdCommentEdit] = useState(null)
	const [idCommentDelete, setIdCommentDelete] = useState(null)
	const [commentDelete,setCommentDelete]=useState(null)

	useQuery(
		[`comentarios`, { id: idReceta }],
		async () => {
			const { data } = await dataProvider.getList(`api/recetas/${idReceta}/comentario`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json , text/plain, */*',
					...(isAuthenticated ? { Authorization: userData.jwt } : {}),
				},
			})
			setCommentsData(data)
			return data
		},
		{
			enabled: showDialog ? true : false,
		}
	)

	function SetForEdition({ idComentario, comentario }) {
		setIdCommentEdit(idComentario)
		setCommentText(comentario)
	}

	function SetForDelete(comment) {
		setIdCommentDelete(comment.idComentario)
		setCommentDelete(comment);
		setShowDialogDelete(true)
	}

	async function PublishComment() {
		if (commentText === '' || !isAuthenticated) return
		const { data } = await dataProvider.create(`api/recetas/${idReceta}/comentario`, {
			data: {
				idComentario: idCommentEdit,
				comentario: commentText,
			},
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
				...(isAuthenticated ? { Authorization: userData.jwt } : {}),
			},
		})
		if (!idCommentEdit) commentsData.push(data)
		else {
			const index = commentsData.findIndex((comment) => comment.idComentario === idCommentEdit)
			if (index !== -1) commentsData[index] = data
		}
		setCommentText('')
		setIdCommentEdit(null)
	}

	function CensorUsername(username) {
		return username.slice(0, 6) + '*'.repeat(username.length - 6)
	}

	async function DeleteRecipe() {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json;charset=UTF-8',
			Authorization: userData.jwt,
		}
		const { status } = await dataProvider.delete(`api/recetas/${idReceta}`, { headers })
		if (status === 200) navigate("/")
	}

	async function DeleteComment() {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json;charset=UTF-8',
			Authorization: userData.jwt,
		}
		const { status } = await dataProvider.delete(`api/recetas/${idReceta}/comentario/${idCommentDelete}`, { headers })
		if (status === 200) {
			const index = commentsData.findIndex((comment) => comment.idComentario === idCommentDelete)
				if (index !== -1) commentsData.slice(index,1)
			
		}

		setShowDialogDelete(false)
	}

	const { EDITAR_PROPIAS, CREAR_VARIANTE, EDITAR_TODAS, CLONAR_RECETA, ELIMINAR_RESENAS } = PRIVILEGIOS
	const { PUBLIC, IN_REVIEW } = RECIPE_STATUS

	const status =
		recipePageData &&
		(Number.isInteger(recipePageData.estado) ? recipePageData.estado : recipePageData.estado.idEstado)

		const isVariant=recipePageData && recipePageData.idRecetaOrigen ? true:false			

		const createdByUser =
		recipePageData &&
		((!isVariant && recipePageData.traducciones[translate].chefReferente &&
			userData.usuario &&
			recipePageData.traducciones[translate].chefReferente.trim() === userData.usuario.trim()) ||
			(recipePageData.traducciones[translate].adaptador &&
				userData.usuario &&
				recipePageData.traducciones[translate].adaptador.trim() === userData.usuario.trim()))

	/*const createdByUser =
		recipePageData &&
		((recipePageData.traducciones[translate].chefReferente &&
			userData.usuario &&
			recipePageData.traducciones[translate].chefReferente.trim() === userData.usuario.trim()) ||
			(recipePageData.traducciones[translate].adaptador &&
				userData.usuario &&
				recipePageData.traducciones[translate].adaptador.trim() === userData.usuario.trim()))*/

	const create_action =
		isAuthenticated &&
		status &&
		[PUBLIC].includes(status) &&
		tienePermisoDe(CREAR_VARIANTE, userData)
	const edit_action =
		isAuthenticated &&
		(tienePermisoDe(EDITAR_TODAS, userData) ||
			(createdByUser &&
				tienePermisoDe(EDITAR_PROPIAS, userData) &&
				status &&
				![PUBLIC].includes(status)))

	const clone_action =
				isAuthenticated &&
				(tienePermisoDe(CLONAR_RECETA, userData) &&
						status &&
						([PUBLIC].includes(status) || [IN_REVIEW].includes(status)))					

	// Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return (
		<div className="recipe-cont">
			{recipePageIsLoading ? (
				<div className="sweet-loading mt-5 pt-5">
					<BeatLoader color={"#509F2C"} loading={recipePageIsLoading} cssOverride={override} size={20} />
				</div>
			) : !recipePageIsError ? (
				recipePageData && (
					<>
						{isAuthenticated && serviceProvider.isFoldersToRecipeLightBoxActivated() && <FoldersToRecipeLightbox />}
						<div>
							<section id="intro" className={`recipe-id-${idReceta}`} ref={componentRef}>
								<div className="container container-3">
									<div className="cyg-row d-flex">
										<div className="cyg-col-left">
											<figure className="ratio-75">
												{recipePageData.fotosReceta &&
												recipePageData.fotosReceta.length > 0 &&
												recipePageData.fotosReceta[0].urlImagen ? (
													<img
														src={`${recipePageData.fotosReceta[0].urlImagen}`}
														alt={`${recipePageData.fotosReceta[0].nombreImagen}`}
													/>
												) : (
													<></>
												)}
											</figure>
										</div>
										<div className="cyg-col-right">
											<div className="cont recipe-panel">
												<header className="tit">
													<h2>{recipePageData.traducciones[translate].titulo}</h2>
													<h4>{recipePageData.traducciones[translate].descripcion}</h4>
												</header>
												<section className="cont-select-nombre-receta">
													{recipePageData && recipePageData.traducciones[translate] && (
														<table>
															<tbody>
																{!originalRecipeIsLoading ? (
																	!originalRecipeIsError ? (
																		originalRecipeData && (
																			<tr>
																				<td>{t("original_recipe")}</td>
																				<td>
																					<Link
																						to={`/receta/${
																							originalRecipeData.idReceta
																						}/${slugify(
																							originalRecipeData.traducciones[
																								originalRecipeTranslate
																							].titulo
																						)}`}
																						className="footer"
																					>
																						<b>
																							{
																								originalRecipeData.traducciones[
																									originalRecipeTranslate
																								].titulo
																							}
																						</b>
																					</Link>
																				</td>
																			</tr>
																		)
																	) : (
																		<tr>
																			<td>
																				<p>{originalRecipeError}</p>
																			</td>
																		</tr>
																	)
																) : (
																	<tr>
																		<td>
																			<BeatLoader
																				color={"#509F2C"}
																				loading={difficultiesIsLoading}
																				cssOverride={override}
																				size={20}
																			/>
																		</td>
																	</tr>
																)}
																<tr>
																	<td>{t("chef")}</td>
																	<td>
																		<b>{recipePageData.traducciones[translate].chefReferente}</b>
																	</td>
																</tr>
																{recipePageData.traducciones[translate].adaptador && (
																	<tr>
																		<td>{t("adapter")}</td>
																		<td>
																			<b>{recipePageData.traducciones[translate].adaptador}</b>
																		</td>
																	</tr>
																)}
																<tr>
																	<td>{t("editor")}</td>
																	<td>
																		<b>{recipePageData.traducciones[translate].editor || "-"}</b>
																	</td>
																</tr>
																<tr>
																	<td>{t("rating")}</td>
																	<td>
																		{recipePageData && recipePageData.traducciones[translate] && (
																			<StarRating
																				score={recipePageData.traducciones[translate].puntuacion}
																				iconClass="icon-estrella-gris"
																			/>
																		)}
																	</td>
																</tr>
																{isAuthenticated && isFavourite && (
																	<tr>
																		<td>{t("rate")}</td>
																		<td>
																			{recipePageData && recipePageData.traducciones[translate] && (
																				<StarRatingForm
																					idReceta={idReceta}
																					idTraduccion={recipePageData.traducciones[translate].idTraduccion}
																					scoreInit={
																						recipePageData.traducciones[translate]
																							.puntuacionUsuario
																					}
																				/>
																			)}
																		</td>
																	</tr>
																)}
															</tbody>
														</table>
													)}
												</section>
												<section className="section-flex">
													{clone_action && (
														<EditRecipeButton
															recipeData={recipePageData}
															className="btn btn-primary btn-enviar btn-clon btn-previa pc"
															action="clonar-receta"
														/>
													)}
													{[IN_REVIEW].includes(status) && (
														<div className="comment-new yellow">
															<p className="comment-note">{t("recipe_under_review")}</p>
														</div>
													)}
												</section>
												<section className="mod-botones d-flex">
													<Tooltip id="recipe-page-tooltip" />
													{isAuthenticated && ![IN_REVIEW].includes(status) && (
														<FavoriteButton
															idReceta={idReceta}
															className={`mas transition ${isFavourite ? "activo" : ""}`}
															topClassName={""}
															itsOnPage={true}
														/>
													)}
													{create_action && (
														<EditRecipeButton
															recipeData={recipePageData}
															className="btn-variantes"
															action="variante-receta"
														/>
													)}

													{edit_action && (
														<EditRecipeButton
															recipeData={recipePageData}
															className="btn-editar"
															action="editar-receta"
														/>
													)}
													{create_action && i18n.resolvedLanguage === "es" && (
														<i
															className="btn-comentarios2"
															data-tooltip-id="recipe-page-tooltip"
															data-tooltip-content={t("create_assisted_variant")}
															onClick={() => {
																navigate(`/crear-receta-ia/modificar/${idReceta}`);
															}}
														/>
													)}
													{isAuthenticated && (
														<i
															className="btn-agregar-carpeta"
															data-tooltip-id="recipe-page-tooltip"
															data-tooltip-content={t("add_to_folder")}
															onClick={() => {
																serviceProvider.foldersToRecipeLightBoxOn();
																serviceProvider.setFoldersToRecipeLightBoxData(recipePageData);
															}}
														/>
													)}
													<i
														className="btn-comentarios"
														data-tooltip-id="recipe-page-tooltip"
														data-tooltip-content={t("view_reviews")}
														onClick={() => {
															setShowDialog(true);
														}}
													/>
													{edit_action && (
														<i
															className="btn-borrar red"
															data-tooltip-id="recipe-page-tooltip"
															data-tooltip-content={t("delete_recipe")}
															onClick={DeleteRecipe}
														/>
													)}
												</section>

												{!variantRecipesIsLoading && !variantRecipesIsError && variantRecipesData.length > 0 && (
													<section>
														{variantRecipesData.map((vRecipe, index) => {
															const slug_title = slugify(vRecipe.titulo);
															return (
																<button
																	key={index}
																	className="btn btn-primary separator"
																	onClick={() => {
																		navigate(`/receta/${vRecipe.idReceta}/${slug_title}`);
																	}}
																>
																	{vRecipe.porciones} {t("servings")}
																</button>
															);
														})}
													</section>
												)}

												<section className="mod-iconos">
													<div className="facil">
														{!difficultiesIsLoading ? (
															!difficultiesIsError ? (
																<>
																	<i>
																		{recipePageData.idDificultad === DIFFICULTY_LEVELS.EASY ? (
																			<IcoFac />
																		) : recipePageData.idDificultad ===
																		  DIFFICULTY_LEVELS.INTERMEDIATE ? (
																			<IcoMed />
																		) : recipePageData.idDificultad === DIFFICULTY_LEVELS.HARD ? (
																			<IcoDif />
																		) : (
																			<></>
																		)}
																	</i>
																	<span>
																		{t([
																			`difficulty.${difficultiesData[recipePageData.idDificultad]}`,
																			difficultiesData[recipePageData.idDificultad]
																		])}
																	</span>
																</>
															) : (
																<p>{difficultiesError}</p>
															)
														) : (
															<div className="sweet-loading mt-5 pt-5">
																<BeatLoader
																	color={"#509F2C"}
																	loading={difficultiesIsLoading}
																	cssOverride={override}
																	size={20}
																/>
															</div>
														)}
													</div>
													<div className="min">
														<i>
															<Ico2 />
														</i>
														<span>{formatTime(recipePageData.traducciones[translate].tiempoChef)}</span>
													</div>
													<div className="tiempo">
														<i>
															<Ico3 />
														</i>
														<span>{formatTime(recipePageData.tiempoPreparacion)}</span>
													</div>
													<div className="prep">
														<i>
															<Ico4 />
														</i>
														<span>
															{recipePageData.traducciones && recipePageData.traducciones[translate].porciones
																? recipePageData.traducciones[translate].porciones
																: "--"}
															&nbsp; {t("servings")}
														</span>
													</div>
												</section>
											</div>
										</div>
									</div>
									<div className="cyg-col-full">
										<div className="linea bg-verde"></div>
									</div>
								</div>
								<figure className="bg-textura bg-verde-rgba-2"></figure>
							</section>

							<section className="info">
								<div className="container container-3">
									<div className="cyg-row">
										<div className="cyg-col-right">
											<section className="bg-verde-rgba-1 mod-ingredientes">
												<header className="tit">
													<h3>{t("ingredients")}</h3>
												</header>
												<article>
													{ingredientesRO
														.sort((a, b) => (a.principal === b.principal ? 0 : a.principal ? -1 : 1))
														.map((ingrediente, index) => {
															return <p key={index}>{ingrediente.descripcion}</p>;
														})}
												</article>
												<button
													className="comment-btn btn-right"
													onClick={() => {
														const ingrText = ingredientesRO.map(ingr => ingr.descripcion).join("\n");
														navigator.clipboard?.writeText(ingrText);
													}}
												>
													{t("copy")}
												</button>
											</section>
											{isAuthenticated &&
												tienePermisoDe(EDITAR_TODAS, userData) &&
												!originalRecipeIsLoading &&
												!originalRecipeIsError &&
												originalRecipeData && (
													<section className="bg-verde-rgba-2 mod-ingredientes">
														<header className="tit">
															<h3>Ingredientes receta original</h3>
														</header>
														<article>
															{originalRecipeData.traducciones &&
															Array.isArray(
																originalRecipeData.traducciones[originalRecipeTranslate].ingredientes
															)
																? originalRecipeData.traducciones[originalRecipeTranslate].ingredientes.map(
																		(ingrediente, index) => {
																			return <p key={index}>{ingrediente.descripcion}</p>;
																		}
																  )
																: "No se han cargado todavía"}
														</article>
													</section>
												)}
											<section className="mod-valores">
												<header className="tit">
													<h3>{t("nutritional_values")}</h3>
													<h6>{t("per_100g_of_food")}</h6>
												</header>
												<table>
													<tbody>
														{recipePageData.traducciones &&
														Array.isArray(recipePageData.traducciones[translate].valoresNutricionales)
															? recipePageData.traducciones[translate].valoresNutricionales.map(
																	(valorNutricional, index) => {
																		return (
																			<tr key={index}>
																				<th>
																					{t([
																						`nutritional.${valorNutricional.nombreNutriente}`,
																						valorNutricional.nombreNutriente
																					])}
																				</th>
																				<td>{`${
																					valorNutricional.cantidad
																						? valorNutricional.cantidad
																						: "0"
																				} ${t([
																					`units.${valorNutricional.nombreUnidad}`,
																					valorNutricional.nombreUnidad
																				])}`}</td>
																				{valorNutricional.idNutriente !== 34 && (
																					<td>{`${(valorNutricional.idNutriente === 5
																						? valorNutricional.cantidad / 1000
																						: valorNutricional.cantidad
																					)?.toFixed(2)} %`}</td>
																				)}
																			</tr>
																		);
																	}
															  )
															: "No se han cargado todavía"}
													</tbody>
												</table>
											</section>
										</div>
										<div className="cyg-col-left pl-30">
											<section className="mod mod-preparacion">
												<header className="tit">
													<h3>
														{t("preparation")}{" "}
														{recipePageData.traducciones[translate].titulo
															? recipePageData.traducciones[translate].titulo
															: ""}
													</h3>
												</header>
												{/*!isAuthenticated && (
													<div className="comment-new yellow">
														<p className="comment-note">{t("login_or_guest_register")}</p>
													</div>
												)*/}
												<CookingStepsText steps={recipePageData.traducciones[translate].pasos} />
											</section>

											<section className="mod mod-etiquetas vg">
												<header className="tit">
													<h3>{t("recommended_for")}</h3>
												</header>
												<section>
													{Array.isArray(recipePageData.etiquetas)
														? recipePageData.etiquetas.map((tag, index) => (
																<div key={index} className="etiqueta transition">
																	<span className="link-recipe-page">
																		{t([`labels.${tag.nombreEtiqueta}`, tag.nombreEtiqueta])}
																	</span>
																</div>
														  ))
														: t("no_loaded_labels")}
												</section>
											</section>

											{recipePageData.traducciones && recipePageData.traducciones[translate].notasChef ? (
												<section className="mod mod-notas">
													<header className="tit">
														<h3>{t("chef_notes")}</h3>
													</header>
													<article>
														{recipePageData.traducciones[translate].notasChef.split("\n").map((line, index) => (
															<p key={index}>{line}</p>
														))}
													</article>
												</section>
											) : (
												<></>
											)}

											{recipePageData.traducciones && recipePageData.traducciones[translate].accesorios ? (
												<section className="mod mod-acces">
													<header className="tit">
														<h3>{t("accessories")}</h3>
													</header>
													<article>
														<p>{recipePageData.traducciones[translate].accesorios}</p>
													</article>
												</section>
											) : (
												<></>
											)}

											{!variantRecipesIsLoading ? (
												!variantRecipesIsError ? (
													<>
														{variantRecipesData.length > 0 && (
															<section className="mod mb-0" id="variant-recipe-list">
																<header className="tit">
																	<h3>{t("variants")}</h3>
																</header>
																<section className="grid-variantes">
																	{variantRecipesData.map((vRecipe, index) => {
																		const slug_title = slugify(vRecipe.titulo);
																		const urlImagen =
																			vRecipe.fotosReceta && vRecipe.fotosReceta.urlImagen
																				? vRecipe.fotosReceta.urlImagen
																				: null;

																		return (
																			<section key={index} className="mod-variantes">
																				<div className="cont-receta receta ch transition">
																					<Link
																						to={`/receta/${vRecipe.idReceta}/${slug_title}`}
																						className="footer"
																						onClick={() =>
																							window.scrollTo({
																								top: 0,
																								behavior: "smooth"
																							})
																						}
																					>
																						<h2>{vRecipe.titulo}</h2>
																						<h4>{vRecipe.descripcion}</h4>
																						<div className="prep same-line">
																							<i className="icon-svg icon-porcion-blanco"></i>
																							<span className="white-text">
																								{vRecipe.porciones
																									? vRecipe.porciones
																									: "--"}
																								&nbsp; {t("servings")}
																							</span>
																						</div>
																					</Link>
																					<Link
																						to={`/receta/${vRecipe.idReceta}/${slug_title}`}
																						className="bg-degrade"
																						onClick={() =>
																							window.scrollTo({
																								top: 0,
																								behavior: "smooth"
																							})
																						}
																					/>
																					<figure className="ratio-100">
																						{urlImagen && (
																							<img
																								src={`${urlImagen}`}
																								alt={`${vRecipe.titulo}`}
																							/>
																						)}
																					</figure>
																				</div>
																			</section>
																		);
																	})}
																</section>
															</section>
														)}
													</>
												) : (
													<p>{variantRecipesError}</p>
												)
											) : (
												<div className="sweet-loading mt-5 pt-5">
													<BeatLoader
														color={"#509F2C"}
														loading={variantRecipesIsLoading}
														cssOverride={override}
														size={20}
													/>
												</div>
											)}

											<section className="d-flex">
												{!recipePageData.traducciones[translate].puntuacion && (
													<section className="mod-validar d-flex click-imp" onClick={handleValidation}>
														<div id="btn-validar" className="transition">
															{t("validate_recipe")}
														</div>
													</section>
												)}
												<section className="mod-imprimir d-flex click-imp" onClick={handlePrint}>
													<div id="btn-imprimir" className="transition">
														{t("print_recipe")}
														<IcoImprimir />
													</div>
												</section>
											</section>
										</div>
									</div>
								</div>
							</section>
						</div>
					</>
				)
			) : (
				<p>{recipePageError}</p>
			)}
			{showDialog && (
				<Dialog
					buttons={
						<button className="btn btn-primary btn-enviar btn-salir" onClick={() => setShowDialog(false)}>
							{t("close")}
						</button>
					}
				>
					<h4>{t("recipe_reviews")}</h4>
					<div className="comment-container">
						{commentsData &&
							commentsData.map(comment => (
								<div className="comment" key={comment.idComentario}>
									<div className="comment-user">
										<div className="comment-username">{CensorUsername(comment.usuario.trim())}</div>
										<div className="comment-date">{comment.fechaComentario}</div>
										{isAuthenticated &&
											comment.usuario.trim() === userData.usuario.trim() &&
											recipePageData.traducciones[translate].puntuacionUsuario > 0 && (
												<div className="half-size">
													<StarRating
														score={recipePageData.traducciones[translate].puntuacionUsuario}
														iconClass="icon-estrella-gris"
													/>
												</div>
											)}
										{isAuthenticated && comment.usuario.trim() === userData.usuario.trim() && (
											<button className="comment-btn" onClick={() => SetForEdition(comment)}>
												{t("edit")}
											</button>
										)}
										{isAuthenticated && tienePermisoDe(ELIMINAR_RESENAS, userData) && (
											<button className="comment-remove-btn" onClick={() => SetForDelete(comment)}>
												{t("delete")}
											</button>
										)}
									</div>
									<div className="comment-text">{comment.comentario}</div>
								</div>
							))}
						{isAuthenticated && isFavourite && (
							<div className="comment-new">
								<textarea
									className="comment-input"
									placeholder={t("write_a_review")}
									value={commentText}
									onChange={e => setCommentText(e.target.value)}
								/>
								<button className="comment-btn" disabled={!commentText} onClick={PublishComment}>
									{idCommentEdit ? t("edit") : t("publish")}
								</button>
							</div>
						)}
						{isAuthenticated && !isFavourite && (
							<div className="comment-new">
								<p className="comment-note">{t("favorite_to_comment")}</p>
							</div>
						)}
					</div>
				</Dialog>
			)}
			{showDialogDelete && (
				<Dialog
					buttons={
						<>
							{idCommentDelete && (
								<button className="btn btn-primary btn-enviar" onClick={() => DeleteComment()}>
									{t("confirm_yes")}
								</button>
							)}
							<button className="btn btn-primary btn-enviar btn-salir" onClick={() => setShowDialogDelete(false)}>
								{t("confirm_no")}
							</button>
						</>
					}
				>
					{commentDelete && (
						<>
							<div className="comment" key={commentDelete.idComentario}>
								<div className="comment-user">
									<div className="comment-username">{CensorUsername(commentDelete.usuario.trim())}</div>
									<div className="comment-date">{commentDelete.fechaComentario}</div>
								</div>
								<div className="comment-text">{commentDelete.comentario}</div>
							</div>
						</>
					)}
					<div class="comment-delete">
						<p>{t("confirm_delete")}</p>
					</div>
				</Dialog>
			)}
		</div>
	);
}

export default RecipePage
