import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDataProvider } from "../../providers/data";
import { slugify } from "../../providers/service/Utils";
import Select from 'react-select'
import "../ToolsPage/Referral.scss";

const Comentarios = () => {
	const dataProvider = useDataProvider();

	const [orden, setOrden] = useState("comentario");
	const [comentarios, setComentarios] = useState([]);

	const { data: comentariosData } = useQuery(["comments"], async () => {
		const response = await dataProvider.getList("/api/recetas/comentarios");
		return response.data;
	});

	useEffect(() => {
		if (!comentariosData) return;
		const sorted = comentariosData.sort((a, b) => {
			if (orden === "comentario")
				return a.idComentario - b.idComentario;
			return a.idReceta - b.idReceta;
		});
		setComentarios([...sorted]); // https://stackoverflow.com/questions/73066686
	}, [orden, comentariosData]);

	return (
		<div className="referrals">
			<div className="cyg-row">
				{comentarios && (
					<div className="cyg-col-full">
						<h4 className="usuario-herr">Reseñas y comentarios</h4>

						<div className="sort-div">
							<label>Ordenar por:</label>
							<Select
								options={[
									{ value: "comentario", label: "Comentario" },
									{ value: "receta", label: "Receta" }
								]}
								value={{ value: orden, label: orden === "receta" ? "Receta" : "Comentario" }}
								onChange={selected => setOrden(selected.value)}
							/>
						</div>

						<table>
							<thead>
								<tr>
									<th>Id Receta</th>
									<th>Titulo de la receta</th>
									<th>Nombre de usuario</th>
									<th>Celular</th>
									<th>Fecha</th>
									<th>Comentario</th>
								</tr>
							</thead>
							<tbody>
								{comentarios.map((comment, index) => (
									<tr key={index}>
										<td className="narrow">{comment.idReceta}</td>
										<td>
											<Link to={`/receta/${comment.idReceta}/${slugify(comment.tituloReceta || "titulo")}`}>
												{comment.tituloReceta || "Sin título" }
											</Link>
										</td>
										<td>{comment.nombreUsuario}</td>
										<td>{comment.celularUsuario}</td>
										<td>{comment.fechaComentario}</td>
										<td className="wide">{comment.comentario}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
};

export default Comentarios;
