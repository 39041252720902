import { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import { useAuthProvider } from '../providers/auth'
import { useDataProvider } from '../providers/data'

function StarRatingForm({ idReceta,idTraduccion, scoreInit }) {
	//Proveedor de autenticación
	const authProvider = useAuthProvider()
	const userData = authProvider.getIdentity()

	//Proveedor de datos
	const dataProvider = useDataProvider()

	const [score, setScore] = useState(scoreInit)
	const [scoreIsSending, setScoreIsSending] = useState(false)

	const handleSubmitScore = async (puntuacion) => {
		setScoreIsSending(true)
		try {
			const { status } = await dataProvider.create(`api/recetas/${idReceta}/puntuacion`, {
				data: {
					puntuacion,
					idTraduccion
				},
				headers: {
					Authorization: userData.jwt,
				},
			})
			if (status) {
				setScore(puntuacion)
				setScoreIsSending(false)
			}
		} catch (error) {
			setScoreIsSending(false)
		}
	}

	return (
		<form className="transition form-ichef">
			<section className="cont-score">
				<section className="mod-estrellas d-flex">
					<div>
						{[1, 2, 3, 4, 5].map((i) => (
							<i
								key={i}
								className={`icon-svg icon-estrella-amarilla${i <= score ? '-activo' : ''}`}
								onClick={() => handleSubmitScore(i)}
							/>
						))}
					</div>
					<BeatLoader
						color={'#509F2C'}
						loading={scoreIsSending}
						cssOverride={{
							marginLeft: "10px",
							marginTop: "10px",
							borderColor: '#509F2C',
						}}
						size={10}
					/>
				</section>
			</section>
		</form>
	)
}

export default StarRatingForm
