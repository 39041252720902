import React from "react";
import { useState, useEffect } from "react";
import { useAuthProvider } from "../providers/auth";
import { defaultDataProvider } from '../providers/data'

const api_path = process.env.REACT_APP_VERCEL_URL

function ShoppingRow({ list }) {
	const authProvider = useAuthProvider();
	const dataProvider = defaultDataProvider(api_path);
	const userData = authProvider.getIdentity();

	const [shoppingList, setShoppingList] = useState([]);
	useEffect(() => {
		const updatedList = list.map(item => {
			const producto = item.formatosVenta[0];
			item.haveAll = false;
			item.haveAmount = 0;
			producto.purchase = producto.fraccionable ? item.peso : producto.amountToPurchase;
			return item;
		});
		setShoppingList(updatedList);
	}, [list]);

	const [phone, setPhone] = useState("");
	useEffect(() => {
		async function fetchPhone() {
			const { data: response } = await dataProvider.getOne("api/users/profile", {
				headers: {
					Authorization: userData.jwt
				}
			});

			const phoneNumber=response.data.phoneNumber;
			if(phoneNumber){
				setPhone(formatNumber(phoneNumber));
			}
			//setPhone(response.data.phoneNumber);
		}
		fetchPhone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	function formatNumber(number) {
		let cleanedNumber = number.replace(/\D/g, '');
	
		if (/^9\d{7}$/.test(cleanedNumber)) {
			return `+598${cleanedNumber}`;
		} else if (/^09\d{7}$/.test(cleanedNumber)) {
			return `+598${cleanedNumber.substring(1)}`;
		} else {
			return cleanedNumber;
		}
	}

	function getTextList() {
		const text = shoppingList
			.map(item => {
				const producto = item.formatosVenta[0];
				if ((producto.fraccionable && item.haveAll) || producto.purchase === 0) return "";
				if (producto.fraccionable) return `${producto.purchase}g ${item.nombreIngr} ${producto.marca}\n`;
				return `${producto.purchase}x ${producto.descripcion} ${producto.marca}\n`;
			})
			.join("");
		return text;
	}

	return (
		<>
			<h4>Lista de compras</h4>
			<div className="align-right">
				<button
					className="btn btn-primary"
					onClick={() => {
						const textList = getTextList();
						navigator.clipboard?.writeText(textList);
					}}
				>
					Copiar lista
				</button>
				<button
					className={`btn btn-primary ${
						!phone && 'is-disabled'
					}`}
					onClick={() => {
						if (!phone) return;
						const textList = getTextList();
						const url = `https://wa.me/${phone}?text=${encodeURIComponent(textList)}`;
						window.open(url, "_blank");
					}}
				>
					Enviar a WhatsApp
				</button>
			</div>

			<div className="row2">
			<table>
				<thead>
					<tr>
						<th>Cantidad</th>
						<th>Ingrediente</th>
						<th>Presentación</th>
						<th>Marca</th>
						<th>Departamento</th>
						<th>Disponibles</th>
						<th>Comprar</th>
						<th>Diferencia</th>
					</tr>
				</thead>

				<tbody>
					{shoppingList.map((item, index) => {
						const producto = item.formatosVenta[0];
						const remaining = producto.fraccionable
							? item.haveAll
								? 0
								: producto.purchase - item.peso
							: producto.presentacion * (item.haveAmount + producto.purchase) - item.peso;

						return (
							<tr key={index}>
								<td>{item.peso}g</td>
								<td>{item.nombreIngr}</td>
								<td>{producto.fraccionable ? "Fraccionable" : producto.descripcion}</td>
								<td>{producto.marca}</td>
								<td>{producto.localVenta.nombre}</td>

								<td>
									{producto.fraccionable ? (
										<span className="side-by-side">
											<input
												className="visible"
												type="checkbox"
												checked={item.haveAll}
												onChange={event => {
													item.haveAll = event.target.checked;
													producto.purchase = item.haveAll ? 0 : item.peso;
													setShoppingList([...shoppingList]);
												}}
											/>
											<p className="checkbox-label">Tengo</p>
										</span>
									) : (
										<span className="side-by-side">
											<input
												className="listInput"
												type="number"
												min={0}
												value={item.haveAmount}
												onChange={event => {
													item.haveAmount = Number(event.target.value);
													producto.purchase = Math.max(producto.amountToPurchase - item.haveAmount, 0);
													setShoppingList([...shoppingList]);
												}}
											/>
										</span>
									)}
								</td>

								<td>
									<span className="side-by-side">
										<input
											className="listInput"
											type={producto.fraccionable ? "" : "number"}
											min={0}
											value={producto.purchase}
											onChange={event => {
												producto.purchase = Number(event.target.value);
												setShoppingList([...shoppingList]);
											}}
										/>
										{producto.fraccionable && <p>g</p>}
									</span>
								</td>

								<td>{remaining >= 0 ? `${remaining}g` : "Insuficiente"}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			</div>
		</>
	);
}

export default ShoppingRow;
