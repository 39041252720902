import { defaultDataProvider } from '../../providers/data'
import { useAuthProvider } from '../../providers/auth'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PaymentMethod from './PaymentMethod'
import Profile from './Profile'
import Referral from './Referral'
import Robot from './Robot'
import SubAccount from './SubAccount'

const api_path = process.env.REACT_APP_VERCEL_URL

const ToolsPage = () => {
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);

	const { t } = useTranslation();
	// Proveedor de autenticación
	const authProvider = useAuthProvider()

	// Proveedor de datos
	const dataProvider = defaultDataProvider(api_path)
	const userData = authProvider.getIdentity()

	const [itemMenuSelected, setItemMenuSelected] = useState(searchParams.get("submenu") || 'perfil')
	const [emptyForm, setEmptyForm] = useState(true)
	const [robotSerial, setRobotSerial] = useState(null)
	const [profileForm, setProfileForm] = useState({
		username: '',
		name: '',
		phoneNumber: '',
		email: '',
		clientId: '',
	})

	const { isLoading, isError, error, data } = useQuery(
		['api/users/profile'],
		async () => {
			const { data: response } = await dataProvider.getOne('api/users/profile', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
			})
			setProfileForm((currentData) => {
				return {
					...currentData,
					...{
						idUsuario:response.data.idUsuario,
						usernameText:response.data.username,
						username: response.data.username,
						name: response.data.name,
						phoneNumber: response.data.phoneNumber,
						email: response.data.email,
						clientId: response.data.clientId,
					},
				}
			})
			setRobotSerial(response.data.serialRobot)
			setEmptyForm(false)
			return response.data
		},
		{
			enabled: emptyForm,
		}
	)

	useEffect(() => {
		if (itemMenuSelected === 'perfil') {
			setEmptyForm(true)
		}
	}, [itemMenuSelected])

	return (
		<div>
			<header id="buscador" style={{ marginBottom: '40px' }}>
				<div className="container container-2">
					<h3>{t("tools")}</h3>
				</div>
				<figure className="bg-textura bg-verde-rgba-2"></figure>
			</header>
			<div className="container container-2">
				<nav className="nav-herr">
					<a
						href="/#"
						className={itemMenuSelected === 'perfil' ? 'activo' : ''}
						onClick={(event) => {
							event.preventDefault()
							setItemMenuSelected('perfil')
						}}
					>
						{t("profile")}
					</a>
					{data && data.serialRobot && (
						<a
							href="/#"
							className={itemMenuSelected === 'robot' ? 'activo' : ''}
							onClick={(event) => {
								event.preventDefault()
								setItemMenuSelected('robot')
							}}
						>
							Robot
						</a>
					)}

					{data && data.serialRobot && (
						<a
							href="/#"
							className={itemMenuSelected === 'paymentMethod' ? 'activo' : ''}
							onClick={(event) => {
								event.preventDefault()
								setItemMenuSelected('paymentMethod')
							}}
						>
							{t("payment_methods")}
						</a>
					)}

					<a
						href="/#"
						className={itemMenuSelected === 'referidos' ? 'activo' : ''}
						onClick={(event) => {
							event.preventDefault()
							setItemMenuSelected('referidos')
						}}
					>
						{t("referrals")}
					</a>
				</nav>

				<section className="sec-herr">
					{!isLoading &&
						(!isError ? (
							{
								perfil: <Profile profileForm={profileForm} setProfileForm={setProfileForm} />,
								robot: <Robot serial={robotSerial} />,
								subcuenta: <SubAccount />,
								paymentMethod: <PaymentMethod />,
								referidos: <Referral profileForm={profileForm}/>,
							}[itemMenuSelected]
						) : (
							<p>{error}</p>
						))}
				</section>
			</div>
		</div>
	)
}

export default ToolsPage
