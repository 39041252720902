import React, { useState } from 'react'
import { useDataProvider } from '../providers/data'
import { useAuthProvider } from '../providers/auth'
import { BeatLoader } from 'react-spinners'
import { useTranslation } from "react-i18next";

function FavoriteButton({ idReceta, className, topClassName, itsOnPage }) {
	const { t,i18n } = useTranslation();
	// Proveedor de autenticación
	const authProvider = useAuthProvider()
	const favorites = authProvider.getFavorites()
	const userData = authProvider.getIdentity()

	const isFavourite = favorites.length ? favorites.includes(Number(idReceta)) : false

	// Proveedor de datos
	const dataProvider = useDataProvider()

	const [isLoading, setIsLoading] = useState(false)

	const handleFavourite = async () => {

		if(isLoading) return;

		setIsLoading(true)

		try {
			if (isFavourite) {
				const response = await dataProvider.delete(`api/favoritos`, {
					data: {
						idReceta: idReceta,
						usuario: userData.usuario,
						idUsuario: userData.idUsuario

					},
					headers: {
						Authorization: userData.jwt,
					},
				})

				if (response) {
					setIsLoading(false)

					if (response.status === 200) {
						authProvider.updateFavorites(favorites.filter((d) => Number(d) !== Number(idReceta)))
					}
				}
			} else {
				const response = await dataProvider.create(`api/favoritos`, {
					data: {
						idReceta: idReceta,
						usuario: userData.usuario,
						idUsuario: userData.idUsuario,
						codLenguaje:i18n.resolvedLanguage

					},
					headers: {
						Authorization: userData.jwt,
					},
				})

				if (response) {
					
					setIsLoading(false)

					if (response.status === 200) {
						authProvider.updateFavorites([...favorites, Number(idReceta)])
					}
				}
			}
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			{!isLoading ? (
				<i className={`${className} ${topClassName}`}
					data-tooltip-id="recipe-page-tooltip"
					data-tooltip-content={isFavourite ? t("remove_from_favorites") : t("add_to_favorites")}
					onClick={() => handleFavourite()}></i>
			) : (
				<BeatLoader
					color={'#509F2C'}
					loading={isLoading}
					size={itsOnPage ? 15 : 8}
					className={topClassName}
					cssOverride={
						itsOnPage
							? {
								display: 'flex',
								alignItems: 'center',
								margin: '0 10px 0 0',
								borderColor: '#509F2C',
								width: 'fit-content',
							}
							: {
								display: 'flex',
								alignItems: 'center',
								position: 'absolute',
								zIndex: '600',
								height: '30px',
								right: '10px',
							}
					}
				/>
			)}
		</>
	)
}

export default FavoriteButton
