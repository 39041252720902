/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import Select, { createFilter } from "react-select";
import { swapEffect } from "../../providers/utils/functions";
import { useAuthProvider } from '../../providers/auth';
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";

function Ingredients({
	ingredientes = [],
	tipoDeVista,
	updateField,
	_insumos,
	pasos: _steps = [],
	portionSize = 0,
	handlePopoverContent = () => {}
}) {
	const { t, i18n } = useTranslation();
	const [allIngredients, setAllIngredients] = useState(ingredientes);
	const [isDisabled, setIsDisabled] = useState(false);
	const [pesoIngrs, setPesoIngrs] = useState(false);
	const [porcionesEstimadas, setPorcionesEstimadas] = useState(0);

	const authProvider = useAuthProvider()
	const userData = authProvider.getIdentity()

	const [partialWeights, setPartialWeights] = useState([]);

	useEffect(() => {
		setIsDisabled(_steps.length > 0);

		let index = 0;
		const weights = [];
		for (const step of _steps) {
			if (step.idOperacion === 6) {
				weights[index] = (weights[index] ?? 0) + parseFloat(step.cantidad);
			} else if (step.idOperacion === 13) {
				index++;
			}
		}
		setPartialWeights(weights);

	}, [_steps]);

	useEffect(() => {
		ingredientes.length && setAllIngredients(ingredientes);

		ActualizarCantidades(ingredientes);
	}, [ingredientes, portionSize]);

	const filterConfig = {
		matchFrom: "any" // start | any
	};

	const voidIngredient = {
		cantidad: "",
		descripcion: "",
		idInsumo: 0,
		idUnidad: userData.useImperial ? 10 : 1,
		nombreInsumo: t("blank"),
		nombreUnidad: userData.useImperial ? "onzas" : "gramos",
		principal: false
	};

	const calcularCapacidad = ingrs => {
		const capacidad = ingrs.reduce((acc, { cantidad, idInsumo }) => {
			if (idInsumo === 982) return acc;
			return acc + parseInt(cantidad);
		}, 0);
		return capacidad;
	};

	function ActualizarCantidades(ingredientes) {
		const capacidad = calcularCapacidad(ingredientes);
		const porciones = portionSize ? Math.round(capacidad / portionSize) : 0;
		setPesoIngrs(capacidad);
		setPorcionesEstimadas(porciones);
	}

	const updateCantidad = (index, nuevaCantidad) => {
		if (!allIngredients[index]) return;
		const { nombreInsumo, /*cantidad: cantidadAnterior, descripcion*/ } = allIngredients[index];
		allIngredients[index]["cantidad"] = nuevaCantidad;
		if (userData.useImperial) {
			allIngredients[index]["descripcion"] = `${nuevaCantidad} oz of ${nombreInsumo?.toLowerCase()}`;
		} else {
			allIngredients[index]["descripcion"] = `${nuevaCantidad} g de ${nombreInsumo?.toLowerCase()}`;
		}
		updateField("ingredientes", { target: { value: allIngredients } });
		ActualizarCantidades(allIngredients);

		/*pasos.forEach(paso => {
			if (paso.descripcion && (paso.descripcion.includes(nombreInsumo?.toLowerCase()) || paso.descripcion.includes(descripcion))) {
				if (paso.idOperacion === 6 && paso.cantidad === cantidadAnterior) {
					if (userData.useImperial) {
						paso.descripcion = `Add ${nuevaCantidad} oz of ${nombreInsumo?.toLowerCase()}`;
					} else {
						paso.descripcion = `Agregar ${nuevaCantidad} gramos de ${nombreInsumo?.toLowerCase()}`;
					}
					paso.cantidad = nuevaCantidad;
					paso.marca = 1;
				}
				// TODO: pasos manuales, ej 2 dientes de ajo vs sal ~ salsa X
			}
		});*/
	};

	// Para cantidad, descripcion, marca
	const fieldChange = (field, i, { target: { value } }) => {
		if (allIngredients[i].field === value) return;
		allIngredients[i] = { ...allIngredients[i], [field]: value };
		setAllIngredients(allIngredients);
		updateField("ingredientes", { target: { value: allIngredients } });
	};

	// Agregar ingrediente
	const updateIngrediente = (i, pos = false) => {
		if (pos === false) allIngredients.push(voidIngredient);
		else allIngredients.splice(pos, 0, voidIngredient);
		setAllIngredients(allIngredients);
		updateField("ingredientes", { target: { value: allIngredients } });
	};

	// Modificar ingrediente
	const changeInsumo = (i, selected) => {
		if (selected) {
			const { label, value } = selected;
			const insumo = _insumos.find(ins => ins.idInsumo === value);
			const idUnidad = voidIngredient.idUnidad;
			const nombreUnidad = voidIngredient.nombreUnidad;
			allIngredients[i] = { ...allIngredients[i], idInsumo: value, nombreInsumo: label, idUnidad, nombreUnidad, marca: 0 };

			const { cantidadOC, unidadOC } = allIngredients[i];
			if (cantidadOC > 0 && unidadOC !== nombreUnidad) {
				let unidadIngr = unidadOC.toLowerCase();
				for (const equivalencia of insumo.equivalencias) {
					const unidad = equivalencia.unidad1.toLowerCase().trim();
					if (unidadIngr.endsWith("s")) {
						unidadIngr = unidadIngr.substring(0, unidad.length - 1);
					}
					if (
						unidad === unidadIngr ||
						(unidad !== "cucharada" &&
							unidad !== "cucharadita" &&
							unidad !== "taza" &&
							unidadIngr !== "litro" &&
							unidadIngr !== "ml")
					) {
						allIngredients[i] = {
							...allIngredients[i],
							nombreUnidad: equivalencia.unidad2,
							cantidad: cantidadOC * parseInt(equivalencia.cantidad2)
						};
						break;
					}
				}
			}
			setAllIngredients([...allIngredients]);
			updateField("ingredientes", { target: { value: allIngredients } });
		}
	};

	const removeIngredient = idx => {
		const all = allIngredients.filter((_, index) => index !== idx);
		setAllIngredients(all);
		updateField("ingredientes", { target: { value: all } });
	};

	const setPrincipal = index => {
		ingredientes[index].principal = !ingredientes[index].principal;
		updateField("ingredientes", { target: { value: ingredientes } });
	};

	const generarPasos = () => {
		var steps = [];
		ingredientes.forEach(ingr => {
			const descripcion = `${t("add")} ${ingr.descripcion}`;
			steps.push({
				numeroPaso: steps.length + 1,
				idOperacion: 6,
				cantidad: ingr.cantidad,
				descripcion
			});
		});
		updateField("pasos", { target: { value: steps } });
	};

	const [isFocus, setFocus] = useState(-1);
	const inputRef = useRef(null);

	function Round(num) {
		if (num === 0) return 0;
		const exp = Math.ceil(Math.log10(Math.abs(num)));
		const power = (exp > 0 ? 2 - exp : 1);
		const magnitude = Math.pow(10, power);
		const shifted = Math.round(num * magnitude);
		return shifted / magnitude;
	}

	useEffect(() => {
		const { current } = inputRef;
		if (isFocus >= 0 && allIngredients.length) {
			const fnd = _insumos.find(({ idInsumo: id }) => id === allIngredients[isFocus].idInsumo);
			const equivalencias = fnd ? fnd.equivalencias : [];
			if (equivalencias.length) {
				handlePopoverContent({
					elementPopover: current,
					contentPopover: equivalencias.map(({ cantidad1, cantidad2, unidad1, unidad2 }, i) => (
						<ins key={`equ${i}`}>
							{cantidad1} {t([`units.${unidad1}`, unidad1])} = {cantidad2} {t([`units.${unidad2}`, unidad2])}
							{userData.useImperial ? ` (${Round(cantidad2 / 28.34952)} oz)` : ""}
						</ins>
					))
				});
				return;
			}
		}
		handlePopoverContent(null);
	}, [isFocus, inputRef, allIngredients]);

	const moveIngredient = (dir, idx) => {
		const dosinfo = [idx, dir === "up" ? idx - 1 : idx + 1];
		const dos = ingredientes.filter((_, i) => dosinfo.includes(i));

		if (dos.length === 2) {
			setFocus(-1);
			swapEffect(
				dosinfo,
				() => {
					const temp = ingredientes[dosinfo[0]];
					ingredientes[dosinfo[0]] = ingredientes[dosinfo[1]];
					ingredientes[dosinfo[1]] = temp;
					updateField("ingredientes", { target: { value: ingredientes } });
				},
				"ingrediente"
			);
		}
	};

	function mod(value, n) {
		return ((value % n) + n) % n;
	}

	const insumosOptions = [
		{
			value: voidIngredient.idInsumo,
			label: voidIngredient.nombreInsumo
		}
	].concat(
		_insumos.map(insumo => ({
			value: insumo.idInsumo,
			label: (i18n.resolvedLanguage === "en" && insumo.traducciones[0]?.nombreInsumo) || (i18n.resolvedLanguage === "pt-BR" && insumo.traducciones[1]?.nombreInsumo) || insumo.nombreInsumo
		}))
	);

	return (
		<article>
			{tipoDeVista === "vista-diseno" ? (
				<div>
					{allIngredients.map(
						(
							{ idInsumo, descripcion, cantidad, nombreUnidad, principal, marca = 0 },
							index
						) => {
							return (
								<section
									key={index}
									className={`content-ingredient ${
										marca ? (marca === 1 ? "bg-warning" : "bg-error") : "bg-verde-rgba-2"
									}`}
									ingrediente={index}
								>
									<div className="add-ingredient">
										<div>
											<div className="edit-cont-select">
												<Select
													placeholder="Nombre del insumo"
													noOptionsMessage={() => "No hay resultados."}
													filterOption={createFilter(filterConfig)}
													options={insumosOptions}
													value={insumosOptions.find(({ value }) => value === idInsumo)}
													onChange={o => changeInsumo(index, o)}
													onFocus={() => setTimeout(() => setFocus(index))}
													onBlur={() => setFocus(-1)}
													menuShouldScrollIntoView={false}
													menuPlacement="auto"
													menuPosition="fixed"
												/>
											</div>

											<div className="input-gramos">
												<input
													value={cantidad}
													onChange={o => {
														updateCantidad(index, o.target.value);
													}}
													placeholder="..."
													onFocus={() => setTimeout(() => setFocus(index))}
													onBlur={() => setFocus(-1)}
												/>
												<span className="nombre-unidad">{t([`units.${nombreUnidad}`, nombreUnidad])}</span>
											</div>
										</div>

										<div className="remove-content">
											<input
												placeholder="Ingrediente"
												value={descripcion}
												onChange={o => {
													fieldChange("descripcion", index, o);
												}}
												onFocus={() => setTimeout(() => setFocus(index))}
												onBlur={() => setFocus(-1)}
												{...(isFocus === index && { ref: inputRef })}
											/>
											<Icon
												type="Cerrar"
												className="remove"
												onClick={() => {
													removeIngredient(index);
												}}
											/>
										</div>
										<section className="sec-eliminar--ing">
											<div className="cont-check">
												<span className="span-label">{t("is_main")}</span>
												<input
													type="checkbox"
													name="check_1"
													required
													autoComplete="off"
													id="check-1"
													className="check"
													checked={!!principal}
													onChange={() => setPrincipal(index)}
												/>
											</div>
											<div className="controles">
												<Tooltip id="ingr-tooltip" />
												<button
													type="Cerrar"
													className="eliminar"
													onClick={() => removeIngredient(index)}
													data-tooltip-id="ingr-tooltip"
													data-tooltip-content={t("remove")}
												>
													<Icon type="Eliminar" className="step-ico" />
												</button>
												<button
													type="Cerrar"
													className="adicionar"
													onClick={() => updateIngrediente(null, index)}
													data-tooltip-id="ingr-tooltip"
													data-tooltip-content={t("add_above")}
												>
													<Icon type="Insertar" className="step-ico" />
												</button>
												<button
													className="bajar"
													onClick={() => moveIngredient("down", index)}
													data-tooltip-id="ingr-tooltip"
													data-tooltip-content={t("move_down")}
												>
													<Icon type="Bajar" className="step-ico" />
												</button>
												<button
													className="subir"
													onClick={() => moveIngredient("up", index)}
													data-tooltip-id="ingr-tooltip"
													data-tooltip-content={t("move_up")}
												>
													<Icon type="Subir" className="step-ico" />
												</button>
												<button
													className="marcar"
													onClick={() => fieldChange("marca", index, { target: { value: mod(marca - 1, 3) } })}
													data-tooltip-id="ingr-tooltip"
													data-tooltip-content={t("mark")}
												>
													<Icon type="Vista" className="step-ico" />
												</button>
											</div>
										</section>
									</div>
								</section>
							);
						}
					)}
				</div>
			) : (
				<div>
					{allIngredients
						.sort((a, b) => (a.principal === b.principal ? 0 : a.principal ? -1 : 1))
						.map(({ descripcion }, index) => {
							return (
								<section key={index} className="content-ingredient bg-verde-rgba-2" ingrediente={index}>
									<>{descripcion}</>
								</section>
							);
						})}
				</div>
			)}
			{tipoDeVista === "vista-diseno" && (
				<>
					<section className="sec-btn-ingredientes">
						<button type="button" className="btn btn-primary" onClick={updateIngrediente}>
							{t("add")} +
						</button>
						<button
							type="button"
							className={`btn btn-primary ${isDisabled && "is-disabled"}`}
							onClick={() => !isDisabled && generarPasos()}
						>
							{t("incorporate_ingredients")}
						</button>
					</section>
					{i18n.resolvedLanguage === "es" && (
						<section className="porciones">
							{partialWeights.map((weight, i) => (
								<div key={i}>
									Peso en etapa {i + 1}: {weight} gramos
								</div>
							))}
							<div>Peso total ingredientes: {pesoIngrs} gramos</div>
							<div>Tamaño porcion: {portionSize} gramos</div>
							<div>Porciones estimadas: {porcionesEstimadas}</div>
							{partialWeights.some(w => w > 2000) && (
								<section className="bg-warning ingr-warning">
									Es posible que la cantidad de ingredientes supere la capacidad del vaso.
								</section>
							)}
						</section>
					)}
				</>
			)}
		</article>
	);
}

export default Ingredients;
