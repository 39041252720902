import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "./ToggleButton";

function LanguageSelector() {
	const { i18n } = useTranslation();

	const langOptions = [
		{
			label: "Español",
			value: "es"
		},
		{
			label: "English",
			value: "en"
		},
		{
			label: "Português",
			value: "pt-BR"
		}
	];

	const [selectedIndex, setSelectedIndex] = useState(0);
	useEffect(() => {
		setSelectedIndex(langOptions.findIndex(option => option.value === i18n.resolvedLanguage));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.resolvedLanguage]);

	function onChangeLang(value) {
		i18n.changeLanguage(value);
		document.documentElement.lang = value;
	}

	return <ToggleButton options={langOptions} selected={selectedIndex} onSwitch={onChangeLang} />;
}

export default LanguageSelector;
