import React from "react";
import Collapsible from "react-collapsible";

const Tutoriales = () => {
	return (
		<div className="cyg-row">
			<div className="container faq">
				<h2>Tutoriales</h2>

				<Collapsible contentOuterClassName="main-title" trigger="Guías rápidas">
					<div class="row2">
						<div class="column">
							<Collapsible trigger="Guía de velocidades">
								<img src="https://i.ibb.co/sJvCPZQ/guia-velocidad.png" alt="Guía de velocidades" />
							</Collapsible>
						</div>
						<div class="column">
							<Collapsible trigger="Guía de temperaturas">
								<img src="https://i.ibb.co/zmGjtyC/guia-temperatura.png" alt="Guía de temperaturas" />
							</Collapsible>
						</div>
					</div>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Tutoriales iChef">
					<div class="row2">
						<div class="column">
							<Collapsible trigger="Desarmar y rearmar el vaso">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/1091503102114809.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Utilizar la vaporera">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/8/379294184725867.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Funciones preestablecidas">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/1360113118655726.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
						</div>
						<div class="column">
							<Collapsible trigger="Colocar el accesorio batidor">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/732919518923182.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Utilizar la función autolimpiante">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/1555761144998076.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Uso del slicer / cortador">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/2151739892011269.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
						</div>
					</div>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Tutoriales Portal">
					<div class="row2">
						<div class="column">
							<Collapsible trigger="Agregar recetas del portal al iChef">
								<video width="480" height="850" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/7938926216164159.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Agregar recetas a carpeta">
								<video width="640" height="360" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/1361579611902552/null"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Modificar recetas de forma asistida">
								<video width="640" height="360" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/1150199439759841/null"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
						</div>
						<div class="column">
							<Collapsible trigger="Usar la busqueda">
								<video width="640" height="360" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/8/1008367460915880.mp4"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Agregar referido">
								<video width="640" height="360" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/398677512968857/null"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
							<Collapsible trigger="Agregar recetas al robot">
								<video width="640" height="360" controls>
									<source
										src="https://dgihnrxqil95z.cloudfront.net/received-files/wca/20/890450019484257/null"
										type="video/mp4"
									/>
								</video>
							</Collapsible>
						</div>
					</div>
				</Collapsible>
			</div>
		</div>
	);
};

export default Tutoriales;
